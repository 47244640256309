var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ValidationProvider", {
        attrs: {
          vid: _vm.vid,
          rules: _vm.rules,
          name: _vm.name,
          slim: "",
          immediate: _vm.immediate,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c("input", {
                  attrs: { type: "hidden" },
                  domProps: { value: _vm.value },
                }),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.displayErrors(errors),
                        expression: "displayErrors(errors)",
                      },
                    ],
                    staticClass: "text-left font-bold text-red-600 mb-2",
                    class: _vm.errorDisplaySize,
                  },
                  [_vm._v(" " + _vm._s(errors[0]) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }