var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FlInputWrapper", {
    attrs: {
      label: _vm.label,
      sublabel: _vm.sublabel,
      rules: _vm.rules,
      hideNoError: _vm.hideNoError,
      name: _vm.name,
      showError: _vm.showError,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mutableValue,
                    expression: "mutableValue",
                  },
                ],
                key: _vm.inputKey,
                staticClass: "w-full h-10 bg-white border rounded",
                class: [
                  errors.length && _vm.showError
                    ? "bg-red-200 border-red-600"
                    : "border-brown-grey",
                  _vm.disabled ? "bg-light-grey" : "",
                ],
                attrs: { name: _vm.name, disabled: _vm.disabled },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.mutableValue = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _vm.unselected
                  ? _c("option", { attrs: { selected: "", value: "" } }, [
                      _vm._v(_vm._s(_vm.$t("commons.not_selected"))),
                    ])
                  : _vm._e(),
                _vm._l(_vm.options, function (option, key) {
                  return _c(
                    "option",
                    {
                      key: key,
                      domProps: {
                        value: option.value,
                        selected: option.value === _vm.value,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.needsTranslation
                              ? _vm.$t(option.text)
                              : option.text
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }